<div class="navbar-top">
  <div class="logo">
    <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
      <mat-icon>menu</mat-icon>
    </button>
    <span class="logo-text">{{ tenantName }}</span>
  </div>

  <div class="buttons">
    <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
</div>

<div
        class="navbar-scroll-container h-full"
        fusePerfectScrollbar
        [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
>
  <div class="navbar-content h-full flex flex-col">
    <fuse-navigation layout="vertical"></fuse-navigation>

    <div *ngIf="collapsed" class="flex items-center mx-2 p-2 space-x-5 rounded cursor-pointer">
      <mat-icon svgIcon="mat_outline:history" class="icon-size-7"></mat-icon>
    </div>

  </div>
</div>
