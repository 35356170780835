<div class="flex flex-col space-y-4">
    <div class="flex flex-col space-y-1">
        <b>{{ response.title }}</b>
        <span class="text-hint text-md">{{ response.description }}</span>
    </div>
    <div class="flex flex-col space-y-2" *ngIf="!horizontalEvolution">
        <div class="flex flex-col space-y-2" *ngFor="let item of items">
            <b>{{ item.title }}</b>
            <div *ngIf="item.empty">Não informado</div>
            <ng-container *ngIf="!item.empty">
                <ng-container [ngSwitch]="item.type">
                    <div *ngSwitchCase="'datetime'">{{ item.text }}</div>
                    <div *ngSwitchCase="'textbox'">{{ item.text }}</div>
                    <div class="flex items-center space-x-2" *ngSwitchCase="'checkbox'">
                        <mat-icon>{{ item.checked ? "check_box" : "check_box_outline_blank" }}</mat-icon>
                        <span>{{ item.text }}</span>
                    </div>
                    <div *ngSwitchCase="'number'">{{ item.text }}</div>
                    <div *ngSwitchCase="'textarea'" [innerHTML]=" item.text | safeHtml"></div>
                    <div *ngSwitchCase="'email'">
                        <a [href]="'mailto:' + item.email">{{ item.email }}</a>
                    </div>
                    <div class="flex flex-col space-y-1" *ngSwitchCase="'multiple-selection-list'">
                        <div class="flex items-center space-x-2" *ngFor="let item of item.items">
                            <mat-icon>check_circle_outline</mat-icon>
                            <span>{{ item }}</span>
                        </div>
                    </div>
                    <div class="flex items-center space-x-2" *ngSwitchCase="'single-selection-list'">
                        <mat-icon>radio_button_checked</mat-icon>
                        <span>{{ item.item }}</span>
                    </div>
                    <div class="flex items-center space-x-2" *ngSwitchCase="'single-selection-dropdown'">
                        <mat-icon>radio_button_checked</mat-icon>
                        <span>{{ item.item }}</span>
                    </div>
                    <div class="flex flex-col space-y-1" *ngSwitchCase="'multiple-selection-dropdown'">
                        <div class="flex items-center space-x-2" *ngFor="let item of item.items">
                            <mat-icon>check_circle_outline</mat-icon>
                            <span>{{ item }}</span>
                        </div>
                    </div>
                    <div class="flex flex-col space-y-1" *ngSwitchCase="'files'">
                        <div class="flex items-center space-x-2" *ngFor="let file of item.files">
                            <mat-icon>attach_file</mat-icon>
                            <a [href]="file.url" target="_blank">{{ file.name }}</a>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div *ngIf="horizontalEvolution">
        <table mat-table [dataSource]="dataSource">
            <ng-container *ngFor="let column of displayedColumns; let i = index">
                <ng-container [matColumnDef]="column" sticky *ngIf="i === 0">
                    <th mat-header-cell class="min-w-40 max-w-200" *matHeaderCellDef>
                        {{ displayedColumnNames[column] }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container [ngSwitch]="getItem(element, column).type">
                            <div *ngSwitchCase="'datetime'">{{ getItem(element, column).text }}</div>
                            <div *ngSwitchCase="'textbox'">{{ getItem(element, column).text }}</div>
                            <div class="flex items-center space-x-2" *ngSwitchCase="'checkbox'">
                                <mat-icon>{{
                                    getItem(element, column).checked ? "check_box" : "check_box_outline_blank"
                                    }}</mat-icon>
                                <span>{{ getItem(element, column).text }}</span>
                            </div>
                            <div *ngSwitchCase="'number'">{{ getItem(element, column).text }}</div>
                            <div *ngSwitchCase="'textarea'" [innerHTML]="getItem(element, column).text"></div>
                            <div *ngSwitchCase="'email'">
                                <a [href]="'mailto:' + getItem(element, column).email">{{
                                    getItem(element, column).email
                                    }}</a>
                            </div>
                            <div class="flex flex-col space-y-1" *ngSwitchCase="'multiple-selection-list'">
                                <div class="flex items-center space-x-2"
                                    *ngFor="let item of getItem(element, column).items">
                                    <mat-icon>check_circle_outline</mat-icon>
                                    <span>{{ item }}</span>
                                </div>
                            </div>
                            <div class="flex items-center space-x-2" *ngSwitchCase="'single-selection-list'">
                                <mat-icon>radio_button_checked</mat-icon>
                                <span>{{ getItem(element, column).item }}</span>
                            </div>
                            <div class="flex items-center space-x-2" *ngSwitchCase="'single-selection-dropdown'">
                                <mat-icon>radio_button_checked</mat-icon>
                                <span>{{ getItem(element, column).item }}</span>
                            </div>
                            <div class="flex flex-col space-y-1" *ngSwitchCase="'multiple-selection-dropdown'">
                                <div class="flex items-center space-x-2"
                                    *ngFor="let item of getItem(element, column).items">
                                    <mat-icon>check_circle_outline</mat-icon>
                                    <span>{{ item }}</span>
                                </div>
                            </div>
                            <div class="flex flex-col space-y-1" *ngSwitchCase="'files'">
                                <div class="flex items-center space-x-2"
                                    *ngFor="let file of getItem(element, column).items">
                                    <mat-icon>attach_file</mat-icon>
                                    <a [href]="file.url" target="_blank">{{ file.name }}</a>
                                </div>
                            </div>
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container [matColumnDef]="column" *ngIf="i > 0">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="flex space-x-4 items-center pl-4">
                            <div>
                                {{ displayedColumnNames[column] }}
                            </div>
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let element" class="pl-4">
                        <ng-container [ngSwitch]="getItem(element, column).type">
                            <div *ngSwitchCase="'datetime'">{{ getItem(element, column).text }}</div>
                            <div *ngSwitchCase="'textbox'">{{ getItem(element, column).text }}</div>
                            <div class="flex items-center space-x-2" *ngSwitchCase="'checkbox'">
                                <mat-icon>{{
                                    getItem(element, column).checked ? "check_box" : "check_box_outline_blank"
                                    }}</mat-icon>
                                <span>{{ getItem(element, column).text }}</span>
                            </div>
                            <div *ngSwitchCase="'number'">{{ getItem(element, column).text }}</div>
                            <div *ngSwitchCase="'textarea'" [innerHTML]=" getItem(element, column).text | safeHtml">
                            </div>
                            <div *ngSwitchCase="'email'">
                                <a [href]="'mailto:' + getItem(element, column).email">{{
                                    getItem(element, column).email
                                    }}</a>
                            </div>
                            <div class="flex flex-col space-y-1" *ngSwitchCase="'multiple-selection-list'">
                                <div class="flex items-center space-x-2"
                                    *ngFor="let item of getItem(element, column).items">
                                    <mat-icon>check_circle_outline</mat-icon>
                                    <span>{{ item }}</span>
                                </div>
                            </div>
                            <div class="flex items-center space-x-2" *ngSwitchCase="'single-selection-list'">
                                <mat-icon>radio_button_checked</mat-icon>
                                <span>{{ getItem(element, column).item }}</span>
                            </div>
                            <div class="flex items-center space-x-2" *ngSwitchCase="'single-selection-dropdown'">
                                <mat-icon>radio_button_checked</mat-icon>
                                <span>{{ getItem(element, column).item }}</span>
                            </div>
                            <div class="flex flex-col space-y-1" *ngSwitchCase="'multiple-selection-dropdown'">
                                <div class="flex items-center space-x-2"
                                    *ngFor="let item of getItem(element, column).items">
                                    <mat-icon>check_circle_outline</mat-icon>
                                    <span>{{ item }}</span>
                                </div>
                            </div>
                            <div class="flex flex-col space-y-1" *ngSwitchCase="'files'">
                                <div class="flex items-center space-x-2"
                                    *ngFor="let file of getItem(element, column).items">
                                    <mat-icon>attach_file</mat-icon>
                                    <a [href]="file.url" target="_blank">{{ file.name }}</a>
                                </div>
                            </div>
                        </ng-container>
                    </td>
                </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</div>