import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { set, assign, get } from "lodash";
import { format, parseISO } from "date-fns";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";

@Component({
    selector: "form-answer-viewer",
    templateUrl: "./answer-viewer.component.html",
    styleUrls: ["./answer-viewer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormAnswerViewerComponent implements OnInit {
    @Input()
    public response: any;

    @Input()
    horizontalEvolution: boolean = false;

    items: any[] = [];

    displayedColumns: string[] = ["name"];

    displayedColumnNames: any = {
        name: "Nome",
    };

    dataSource: any[] = [];

    constructor(public dialog: MatDialog) {}

    ngOnInit() {
        if (this.horizontalEvolution) {
            let index = 0;
            for (let response of this.response.evolution) {
                let columnName = `column${index++}`;
                this.displayedColumns.push(columnName);
                set(
                    this.displayedColumnNames,
                    columnName,
                    format(moment(response.answers[0].creationTime).toDate(), "dd/MM/yyyy")
                );
                for (let answer of response.answers) {
                    if (this.dataSource.findIndex((e) => e.id === answer.fieldId) >= 0) {
                        continue;
                    }
                    this.dataSource.push({
                        id: answer.fieldId,
                        name: { type: "textbox", text: answer.label },
                    });
                }
            }
            index = 0;
            for (let response of this.response.evolution) {
                let rowIndex = 0;
                let columnName = `column${index++}`;
                for (let entry of response.answers) {
                    let row = this.dataSource[rowIndex++];
                    if (entry.answer) {
                        switch (entry.fieldType) {
                            case "textbox":
                                set(row, columnName, { type: entry.fieldType, text: entry.answer });
                                break;
                            case "checkbox":
                                let checked = entry.answer.toLowerCase() === "true";
                                set(row, columnName, {
                                    type: entry.fieldType,
                                    checked: checked,
                                    text: checked ? "Sim" : "Não",
                                });
                                break;
                            case "textarea":
                                set(row, columnName, {
                                    type: entry.fieldType,
                                    text: entry.answer.replace(/\n/g, "<br>"),
                                });
                                break;
                            case "number":
                                set(row, columnName, { type: entry.fieldType, text: entry.answer });
                                break;
                            case "email":
                                set(row, columnName, { type: entry.fieldType, email: entry.answer });
                                break;
                            case "multiple-selection-list":
                                set(row, columnName, { type: entry.fieldType, items: JSON.parse(entry.answer) });
                                break;
                            case "single-selection-list":
                                set(row, columnName, { type: entry.fieldType, item: entry.answer });
                                break;
                            case "single-selection-dropdown":
                                set(row, columnName, { type: entry.fieldType, item: entry.answer });
                                break;
                            case "multiple-selection-dropdown":
                                set(row, columnName, { type: entry.fieldType, items: JSON.parse(entry.answer) });
                                break;
                            case "datetime":
                                let date: Date = parseISO(entry.answer);
                                set(row, columnName, { type: entry.fieldType, text: date.toLocaleDateString() });
                                break;
                            case "files":
                                let files = [];
                                let filesObj = JSON.parse(entry.answer);
                                for (let key in filesObj) {
                                    files.push({
                                        name: key,
                                        url: filesObj[key],
                                    });
                                }
                                set(row, columnName, { type: entry.fieldType, items: files });
                                break;
                        }
                    }
                }
            }
        } else {
            this.prepare();
        }
    }

    prepare() {
        let lines = [];
        for (let entry of this.response.answers) {
            let line = {
                title: `${entry.label} ${entry.required ? "*" : ""}`,
                type: entry.fieldType,
                empty: !entry.answer,
            };
            if (entry.answer) {
                switch (entry.fieldType) {
                    case "textbox":
                        assign(line, { text: entry.answer });
                        break;
                    case "checkbox":
                        let checked = entry.answer.toLowerCase() === "true";
                        assign(line, {
                            checked: checked,
                            text: checked ? "Sim" : "Não",
                        });
                        break;
                    case "textarea":
                        assign(line, { text: entry.answer.replace(/\n/g, "<br>") });
                        break;
                    case "number":
                        assign(line, { text: entry.answer });
                        break;
                    case "email":
                        assign(line, { email: entry.answer });
                        break;
                    case "multiple-selection-list":
                        assign(line, { items: JSON.parse(entry.answer) });
                        break;
                    case "single-selection-list":
                        assign(line, { item: entry.answer });
                        break;
                    case "single-selection-dropdown":
                        assign(line, { item: entry.answer });
                        break;
                    case "multiple-selection-dropdown":
                        assign(line, { items: JSON.parse(entry.answer) });
                        break;
                    case "datetime":
                        let date: Date = parseISO(entry.answer);
                        assign(line, {
                            text: date.toLocaleDateString(),
                        });
                        break;
                    case "files":
                        let files = [];
                        let filesObj = JSON.parse(entry.answer);
                        for (let key in filesObj) {
                            files.push({
                                name: key,
                                url: filesObj[key],
                            });
                        }
                        assign(line, { files: files });
                        break;
                }
            }
            lines.push(line);
        }
        this.items = lines;
    }

    getItem(e, c) {
        let item = get(e, c);
        if (!item) {
            return { type: "textbox", text: " - " };
        }
        return item;
    }
}
