import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({name: 'safeHtml'})
export class SafeHtmlTextPipe implements PipeTransform
{
    constructor(private sanitizer:DomSanitizer){}
    
    /**
     * Transform
     *
     * @param {string} value
     * @param {any[]} args
     * @returns {string}
     */
    transform(value: string, args: any[] = []): any
    {
        // return this.sanitizer.sanitize(SecurityContext.HTML,this.sanitizer.bypassSecurityTrustHtml(value));
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
}
